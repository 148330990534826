<span fxLayoutAlign="center center" class="item" (click)="makeCall()">
  <mat-icon [color]="iconColor" [ngClass]="'mat-icon-' + iconSize"
    >call</mat-icon
  >
  <span class="px-1">01270888338</span>
</span>
<span *ngIf="dividers" class="v-divider" fxShow="false" fxShow.gt-xs></span>
<span fxLayoutAlign="center center" class="item" fxShow="false" fxShow.gt-xs>
  <mat-icon [color]="iconColor" [ngClass]="'mat-icon-' + iconSize"
    >location_on</mat-icon
  >
  <span class="px-1">Cairo: 12 Abbas El Akkad, Nasr City. </span>
</span>
<span *ngIf="dividers" class="v-divider" fxShow="false" fxShow.gt-sm></span>

