<mat-sidenav-container>
  <mat-sidenav-content
    class="page-wrapper"
    fxLayout="column"
    fxLayoutAlign="start stretch"
  >
    <header
      [ngClass]="'toolbar-' + settings.mainToolbarFixed"
      [class.has-bg-image]="settings.mainToolbarFixed"
      [class.has-bg-video]="settings.mainToolbarFixed"
      [class.main-toolbar-fixed]="settings.mainToolbarFixed"
    >
      <app-toolbar1
        *ngIf="settings.toolbar == 1"
        (onMenuIconClick)="sidenav.toggle()"
      ></app-toolbar1>
    </header>

    <main [class.content-offset-to-top]="settings.contentOffsetToTop">
      <router-outlet></router-outlet>
    </main>

    <div class="sticky-footer-helper" fxFlex="grow"></div>

    <app-footer></app-footer>

    <div
      *ngIf="showBackToTop"
      fxLayout="row"
      fxLayoutAlign="center center"
      class="back-to-top"
      (click)="scrollToTop()"
    >
      <mat-icon>arrow_upward</mat-icon>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
