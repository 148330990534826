<footer class=" footer">
  <div class="px-3 ">
    <div class="theme-container text-center divider">
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        class="copyright py-1 text-center"
      >
        <p>
          Made with
          <a
            mat-button
            href="https://www.facebook.com/D.Menuss"
            color="primary"
            target="_blank"
            class="d_menu"
            >Digital Menu</a
          >
        </p>
      </div>
    </div>
  </div>
</footer>
